import Cookies from 'js-cookie';
import { NextSeo } from 'next-seo';
import React from 'react';
import Header from 'components/layout/Header';
import Page from 'components/layout/Page';
import BreadcrumbJsonLd from 'components/seo/jsonld/Breadcrumb';
import OrganizationJsonLd from 'components/seo/jsonld/Organization';
import WebsiteJsonLd from 'components/seo/jsonld/Website';
import TechLandingPage from 'components/tech-landing/TechLandingPage';
import { darkColors } from 'styles/colors';

const metadata = {
  title: 'Standard Resume: Build an Impressive Resume in 5 Minutes',
  description: `Make a good first impression with a professional resume template. Our online resume builder helps you create a resume that impresses hiring managers and recruiters.`,
  ogTitle: 'Effortlessly build an impressive resume',
};

class Index extends React.Component {
  componentDidMount() {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        Cookies.set('code', code, { domain: `.${window.location.hostname}` });
      }
    }
  }
  render() {
    return (
      <>
        <NextSeo
          title={metadata.title}
          description={metadata.description}
          openGraph={{ title: metadata.ogTitle }}
        />
        <WebsiteJsonLd />
        <OrganizationJsonLd />
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: process.env.NEXT_PUBLIC_WEBSITE_URL,
            },
          ]}
        />
        <Page
          backgroundColor={darkColors.buttonGray}
          CustomHeader={<Header dark={true} />}
          isDark={true}
        >
          <TechLandingPage />
        </Page>
      </>
    );
  }
}

function isBrowser() {
  return !!typeof window;
}

export default Index;
