import Head from 'next/head';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Organization, WithContext } from 'schema-dts';

export const organizationConstants = {
  id: 'https://standardresume.co/#organization',
  name: 'Standard Resume',
  url: 'https://standardresume.co/',
  logoUrl: `https://assets.standardresume.co/image/upload/v1607702827/logos/logo_pnr5v6.png`,
};

export default function OrganizationJsonLd() {
  const organizationJsonLd: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': organizationConstants.id,
    name: organizationConstants.name,
    url: organizationConstants.url,
    logo: {
      '@type': 'ImageObject',
      url: organizationConstants.logoUrl,
    },
    sameAs: [
      'https://www.linkedin.com/company/standard-resume',
      'https://twitter.com/StandardResume',
      'https://www.facebook.com/standardresume/',
      'https://www.youtube.com/channel/UCKbJ7yTWNqJrLqDT3usGEFg',
    ],
    email: 'hello@standardresume.co',
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'hello@standardresume.co',
      contactType: 'Customer support & inquiries',
      availableLanguage: 'EN',
    },
    knowsAbout: ['Resumes', 'Hiring'],
    foundingDate: '2015-05-09',
    foundingLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Vancouver',
        addressRegion: 'BC',
        addressCountry: 'CA',
      },
    },
    founder: [
      {
        '@type': 'Person',
        '@id': 'https://standardresume.co/r/rileyt/#person',
        name: 'Riley Tomasek',
        jobTitle: 'Co-Founder',
        knowsAbout: ['Resumes', 'Hiring', 'Software'],
        sameAs: [
          'https://www.linkedin.com/in/rileyt/',
          'https://standardresume.co/r/rileyt',
        ],
        email: 'riley@standardresume.co',
        worksFor: {
          '@type': 'Organization',
          '@id': 'https://standardresume.co/#organization',
        },
      },
      {
        '@type': 'Person',
        '@id': 'https://standardresume.co/r/sari/#person',
        name: 'Sari Maani',
        jobTitle: 'Co-Founder',
        knowsAbout: ['Resumes', 'Graphic design', 'Hiring'],
        sameAs: [
          'https://www.linkedin.com/in/sari-maani-8023a4a/',
          'https://standardresume.co/r/sari',
        ],
        email: 'sari@standardresume.co',
        worksFor: {
          '@type': 'Organization',
          '@id': 'https://standardresume.co/#organization',
        },
      },
      {
        '@type': 'Person',
        '@id': 'https://standardresume.co/r/sinan/#person',
        name: 'Sinan Maani',
        jobTitle: 'Co-Founder',
        knowsAbout: ['Resumes', 'Hiring', 'Design'],
        sameAs: [
          'https://www.linkedin.com/in/sinan-maani/',
          'https://standardresume.co/r/sinan',
        ],
        email: 'sinan@standardresume.co',
        worksFor: {
          '@type': 'Organization',
          '@id': 'https://standardresume.co/#organization',
        },
      },
    ],
  };

  return (
    <Head>
      <script
        key="organization-jsonld"
        {...jsonLdScriptProps<Organization>(organizationJsonLd)}
      />
    </Head>
  );
}
