import Head from 'next/head';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { BreadcrumbList, WithContext } from 'schema-dts';

export interface ItemListElement {
  item: string;
  name: string;
  position: number;
}

export interface BreadcrumbJsonLdProps {
  itemListElements: ItemListElement[];
}

export default function BreadcrumbJsonLd({
  itemListElements,
}: BreadcrumbJsonLdProps) {
  const breadcrumbJsonLd: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElements.map((item) => {
      return {
        '@type': 'ListItem',
        position: item.position,
        item: item.item,
        name: item.name,
      };
    }),
  };

  return (
    <Head>
      <script
        key="breadcrumb-jsonld"
        {...jsonLdScriptProps<BreadcrumbList>(breadcrumbJsonLd)}
      />
    </Head>
  );
}
