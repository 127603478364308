import Head from 'next/head';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { WebSite, WithContext } from 'schema-dts';
import { organizationConstants } from './Organization';

export default function WebsiteJsonLd() {
  const websiteJsonLd: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': 'https://standardresume.co/#website',
    name: 'Standard Resume',
    url: 'https://standardresume.co/',
    publisher: {
      '@type': 'Organization',
      '@id': organizationConstants.id,
      name: organizationConstants.name,
      logo: {
        '@type': 'ImageObject',
        url: organizationConstants.logoUrl,
      },
    },
  };

  return (
    <Head>
      <script
        key="website-jsonld"
        {...jsonLdScriptProps<WebSite>(websiteJsonLd)}
      />
    </Head>
  );
}
